import React from "react";

export default function Card({ image, imageAlt, title, details }) {
  return (
    <section className={`basis-full  md:basis-1/2 lg:basis-1/3  p-0 md:p-5`}>
      {/* <div className="basis-full"> */}
      <article className="group/cardImg sm:min-h-[75vh] md:min-h-[76vh] lg:min-h-[58vh] space-y-[25px] py-4 px-3 md:px-4 border border-white/20 bg-gradient-to-tr from-[#181C61] to-[#2E33A4] cursor-pointer">
        {/* Image */}
        <div className="w-[70px] h-[70px] md:w-[90px] md:h-[90px]">
          <img
            className="h-full w-full group-hover/cardImg:scale-110 duration-300 ease-in-out"
            src={image}
            alt={imageAlt}
            loading="lazy"
            srcset=""
          />
        </div>
        {/* Details */}
        <div className="">
          <header className="font-[600] w-1/2 text-[24px] text-white leading-[31.81px] cursor-text">
            {title}
          </header>
          <p className="font-[600] text-xs text-white leading-[23.52px] tracking-wider cursor-text">
            {details}
          </p>
        </div>
        {/* Learn more btn */}
        <button className="group/item flex flex-wrap justify-center items-center space-x-3 font-[700] text-[16px] leading-[23.52px] tracking-widest text-white">
          <header>Learn more</header>
          <i className="group-hover/item:ps-2 ri-arrow-right-up-line font-light text-3xl duration-500 ease-in-out"></i>
        </button>
      </article>
      {/* </div> */}
    </section>
  );
}
