import React from "react";
import Header from "../Helper/Header";
import FirstSections from "./FirstSection";
const sectionHeader_paragraph =
  "We have worked on multiple projects that is helping our customer to generate millions of dollar in revernue per year , Because we put emphasis on the quality which eventually help our client to get success in their business";
export default function Projects() {
  return (
    <section className="BrandsWeHaveWorkedWith-Card-bg px-16 py-[84px] space-y-6">
      <Header
        title="Projects"
        titleTextColor="header-text-Brand-Color"
        paragraph={sectionHeader_paragraph}
      />
      <FirstSections />
    </section>
  );
}
