import React from "react";
import Header from "../../Helper/Header.jsx";
import Brands from "../Brands";

export default function SectionHeader() {
  return (
    <article className="">
      <Header title="Brands we have worked with" />
      <Brands />
    </article>
  );
}
