import React from "react";

export default function CardHeader({
  card_1st_Title,
  card_2nd_Title,
  card_1st_Paragraph,
}) {
  return (
    <div className="text-start space-y-3 w-full">
      <h1
        className={`header-text-Brand-Color font-[800] leading-[44.47px] bellow-270px-text-lg bellow-370px-text-xl bellow-470px-text-2xl text-3xl md:text-[36px]`}
      >
        {card_1st_Title}
      </h1>
      <h4 className="header-text-Light-Brand-Color font-[700] text-lg">
        {card_2nd_Title}
      </h4>
      <p
        className={`text-Brand-Light-Brand-color font-[400] text-xs w-full sm:text-sm sm:w-[60%]`}
      >
        {card_1st_Paragraph}
      </p>
    </div>
  );
}
