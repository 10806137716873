import React from "react";
import ImageContainer from "./ImageContainer";
import Details from "./Details";

export default function FirstSections() {
  return (
    <section className="flex flex-wrap justify-center items-center lg:py-12">
      <Details />
      <ImageContainer />
    </section>
  );
}
