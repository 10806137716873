import React from "react";
import WebsiteDevelopmentPng from "../../../Images/Our Service/Mask group.png";
import ERP_CRMPng from "../../../Images/Our Service/Group 1000001892.png";
import Mobile_WebApplication from "../../../Images/Our Service/Mask group (1).png";
import SaaSPng from "../../../Images/Our Service/Mask group (2).png";
import E_commerce_DevelopmentPng from "../../../Images/Our Service/Group 1000001888.png";
import CustomSoftwarePng from "../../../Images/Our Service/Group 1000001894.png";

import Card from "./Card";

const cardDetailsArray = [
  {
    title: "Website Development",
    details:
      "Our team creates captivating websites that leave a lasting impression, blending stunning design with seamless functionality for an exceptional user experience.",
    image: WebsiteDevelopmentPng,
    imageAlt: "Website DevelopmentPng",
  },
  {
    title: "ERP & CRM",
    details:
      "Streamline operations, optimize workflows, and boost customer relations with our tailored ERP and CRM solutions, empowering your business with efficient management tools.",
    image: ERP_CRMPng,
    imageAlt: "ERP & CRM_Png",
  },
  {
    title: "Mobile & web Application",
    details:
      "Engage users on-the-go effortlessly and seamlessly with our high-performance mobile and web applications, delivering intuitive interfaces and top-notch functionality.",
    image: Mobile_WebApplication,
    imageAlt: "Mobile WebApplication_Png",
  },
  {
    title: "SaaS",
    details:
      "Embrace the future of software delivery with our secure, reliable, and highly scalable Software-as-a-Service solutions, providing unmatched flexibility and accessibility from anywhere.",
    image: SaaSPng,
    imageAlt: "SaaS_Png",
  },
  {
    title: "E-commerce Development",
    details:
      "Drive online success with our expertly crafted custom e-commerce solutions, delivering captivating stores that maximize sales, enhance user experience, and ensure customer satisfaction.",
    image: E_commerce_DevelopmentPng,
    imageAlt: "E commerce Development_Png",
  },
  {
    title: "Custom Software",
    details:
      "Address unique challenges with our bespoke software development services, where our experts transform your ideas into innovative and efficient software solutions.",
    image: CustomSoftwarePng,
    imageAlt: "Custom Software_Png",
  },
];

export default function Cards() {
  return (
    <article className="flex flex-wrap justify-center items-start space-y-6 md:space-y-0 z-[100]">
      {cardDetailsArray.map((element, index) => (
        <Card
          title={element.title}
          details={element.details}
          image={element.image}
          imageAlt={element.imageAlt}
          key={element.details + element.title + index}
        />
      ))}
    </article>
  );
}
