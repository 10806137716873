import React from "react";
import Image from "./Image";
import Details from "./Details";

export default function ImageAndDetails() {
  return (
    <section className="flex flex-wrap justify-center items-center">
      <div className="basis-full md:basis-1/2 h-[580px] md:h-[734px]">
        <Image />
      </div>
      <div className="basis-full md:basis-1/2 h-full">
        <Details />
      </div>
    </section>
  );
}
