import React from "react";
import Group226Png from "../../../../Images/Group 226.png";
import Group227Png from "../../../../Images/Group 227.png";
import Group228Png from "../../../../Images/Group 228.png";

const detail_Card_Array = [
  {
    title: "Technical Advancement",
    details:
      "We take the complete responsibility of building apps which are built to rule the Play Store. Our association doesn't just .",
    img: Group226Png,
    img_Alt: "Technical Advancement",
  },
  {
    title: "Boost your Revenue",
    details:
      "We take the complete responsibility of building apps which are built to rule the Play Store. Our association doesn't just .",
    img: Group227Png,
    img_Alt: "Technical Advancement",
  },
  {
    title: "Technical Advancement",
    details:
      " We take the complete responsibility of building apps which are built to rule the Play Store. Our association doesn't just .",
    img: Group228Png,
    img_Alt: "Technical Advancement",
  },
];
export default function Details() {
  return (
    <article>
      <section className="flex flex-wrap space-y-3 md:space-y-6 ">
        {detail_Card_Array.map((element, index) => (
          <div
            className="basis-full flex  px-2 py-6 md:px-5 bg-white  w-full md:w-2/3"
            key={element.title + index}
          >
            <div className="basis-1/6 h-auto w-auto self-center">
              <img
                className="h-[46px] w-[46px] md:h-[56px] md:w-[56px]"
                src={element.img}
                alt={element.img_Alt}
                loading="lazy"
              />
            </div>
            <div className="basis-10/12 px-2 items-start space-y-3">
              <header className="header-text-Brand-Color font-[600] text-base md:text-[18px] leading-[22.23px] ">
                {element.title}
              </header>
              <p className="text-Brand-Light-Brand-color font-[600] leading-[19.78px] text-xs ">
                {element.details}
              </p>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
}
