import React from "react";
import rightTechBg1Png from "../../../Images/right-tech-bg 1.png";

export default function RightTechBgTopRight() {
  return (
    <div className="absolute top-0 right-0 z-[-1] w-[60vw] h-[60vw] md:w-[40vw] md:h-[60vh]">
      <img
        className="h-full w-full"
        src={rightTechBg1Png}
        alt="right Tech Bg1_Png"
        loading="lazy"
      />
    </div>
  );
}
