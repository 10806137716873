import React from "react";

import wavesPng from "../../../Images/waves.png";

export default function Waves() {
  return (
    <div className="float-right w-[70px] h-[30px]  md:w-[90.98px] md:h-[51.6px]">
      <img
        src={wavesPng}
        loading="lazy"
        className="w-full h-full"
        alt="waves Png"
      />
    </div>
  );
}
