import React from "react";
import Header from "../../Helper/Header";
import AwardsList from "./AwardsList";
import Waves from "../Waves";
export default function Awards() {
  return (
    <section className="space-y-6">
      <Header title="Awards" />
      <AwardsList />
      <Waves />
    </section>
  );
}
