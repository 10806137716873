import React from "react";
import image181Png from "../../../Images/Projects/image 181.png";

export default function ImageContainer() {
  return (
    <article className="basis-full md:basis-3/5 h-[40vh] sm:h-[530px] w-full">
      <img
        className="w-full h-full"
        src={image181Png}
        loading="lazy"
        alt="image181_Png"
      />
    </article>
  );
}
