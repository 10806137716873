import React from "react";
import SectionHeader from "./SectionHeader";
import WhyChooseUs from "./WhyChooseUs";
import Awards from "./Awards";

export default function BrandsWeHaveWorkedWith() {
  return (
    <section className="BrandsWeHaveWorkedWith-Card-bg px-16 min-h-screen rounded-tr-[130px] rounded-bl-[130px] md:rounded-tr-[200px] md:rounded-bl-[200px] py-[84px]">
      <SectionHeader />
      <WhyChooseUs />
      <Awards />
    </section>
  );
}
