import React from "react";
import EllipseDots from "./EllipseDots";
import Ellipse196Png from "../../../Images/Our Service/Ellipse 196.png";
import Ellipse201Png from "../../../Images/Our Service/Ellipse 201.png";
import Ellipse200Png from "../../../Images/Our Service/Ellipse 200.png";
import Ellipse197Png from "../../../Images/Our Service/Ellipse 197.png";
import Ellipse194Png from "../../../Images/Our Service/Ellipse 194.png";
import Ellipse193Png from "../../../Images/Our Service/Ellipse 193.png";
import Ellipse199Png from "../../../Images/Our Service/Ellipse 199.png";

const EllipseArray = [
  {
    image: Ellipse201Png,
    imageAlt: "Ellipse201_Png",
    css: "w-[7px] h-[7px] top-[70vh] left-[6vw] md:left-[3vw]",
  },
  {
    image: Ellipse196Png,
    imageAlt: "Ellipse196_Png",
    css: "top-[12vh] sm:top-[20vh] left-[18vw] sm:left-[14vw] w-[10px] h-[10px]",
  },
  {
    image: Ellipse200Png,
    imageAlt: "Ellipse200_Png",
    css: "w-[15px] h-[15px] right-7 sm:right-12 top-[26vh]",
  },
  {
    image: Ellipse197Png,
    imageAlt: "Ellipse197_Png",
    css: "w-[8px] h-[8px] top-[50vh] right-3 sm:right-3 lg:left-[64vw]",
  },
  {
    image: Ellipse194Png,
    imageAlt: "Ellipse194_Png",
    css: "w-[10px] h-[10px] right-6 bottom-[34vh] md:left-[49vw] lg:left-[34vw]",
  },
  {
    image: Ellipse193Png,
    imageAlt: "Ellipse193_Png",
    css: "w-[15px] h-[15px] left-4 bottom-[60vh]  md:right-4 md:bottom-[40vh] md:top-[200vh] lg:top-[100vh]",
  },
  {
    image: Ellipse199Png,
    imageAlt: "Ellipse199_Png",
    css: " w-[15px] h-[15px] right-[60vw] bottom-3 ",
  },
];

export default function Dots() {
  return (
    <section className="absolute top-0 left-0 h-[100%] w-[100%] z-[-1] ">
      <div className="relative h-full w-full">
        {EllipseArray.map((element, index) => (
          <EllipseDots
            image={element.image}
            imageAlt={element.imageAlt}
            Alignment_css={element.css}
            key={element.css + index}
          />
        ))}
      </div>
    </section>
  );
}
