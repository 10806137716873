import React from "react";
import rightTechBg1Png from "../../../Images/right-tech-bg 1.png";

export default function RightTechBgBottomLeft() {
  return (
    <div className="absolute -bottom-11  left-0 z-[-1] w-[90vw] h-[60vh] sm:w-[60vw] sm:h-[60vw] md:w-[40vw] md:h-[60vh]">
      <img
        className="h-full w-full"
        src={rightTechBg1Png}
        alt="right Tech Bg1_Png"
        loading="lazy"
      />
    </div>
  );
}
