import React from "react";
import Header from "../Helper/Header";
import Cards from "./Cards";
import RightTechBgTopRight from "./RightTechBgTopRight";
import RightTechBgBottomLeft from "./RightTechBgBottomLeft";
import Dots from "./Dots";

export default function OurService() {
  return (
    <section className="px-16 py-[84px] relative overflow-hidden">
      <Header title="Our Service" titleTextColor="text-[#FFFFFF]" />
      <Cards />
      <RightTechBgTopRight />
      <RightTechBgBottomLeft />
      <Dots />
    </section>
  );
}
