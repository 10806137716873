import propTypes from "prop-types";

export default function SectionHeader({
  title,
  paragraph,
  titleTextColor = "header-text-Brand-Color",
  paragraphTextColor = "text-Brand-Light-Brand-color",
  ParagraphCss,
}) {
  return (
    <header className="text-center space-y-3">
      <h1
        className={`${titleTextColor} text-center font-[800] leading-[44.47px] bellow-270px-text-lg bellow-370px-text-xl bellow-470px-text-2xl text-3xl md:text-[36px]`}
      >
        {title}
      </h1>
      <p
        className={`${paragraphTextColor} font-[400] text-xs w-full sm:text-sm sm:w-[60%] mx-auto ${ParagraphCss}`}
      >
        {paragraph}
      </p>
    </header>
  );
}

SectionHeader.prototype = {
  title: propTypes.string.isRequired,
  titleTextColor: propTypes.string,
  paragraph: propTypes.string,
};
