import React from "react";
import brand_laborator from "../../../Images/BrandsWeHaveWorkedWith/brand_laborator.png";
import brand_cryptoniteIco from "../../../Images/BrandsWeHaveWorkedWith/brand-cryptoniteIco.png";
import brand_maxPlaper from "../../../Images/BrandsWeHaveWorkedWith/brand_maxPlaper.png";
import brand_honey_Hosting from "../../../Images/BrandsWeHaveWorkedWith/brand_honey_Hosting.png";
import brand_dynamic from "../../../Images/BrandsWeHaveWorkedWith/brand_dynamic.png";

const brand_Img_Array = [
  {
    img: brand_laborator,
    alt: "Brand Laborator",
  },
  {
    img: brand_cryptoniteIco,
    alt: "Brand CryptoniteIco",
  },
  {
    img: brand_maxPlaper,
    alt: "Brand MaxPlaper",
  },
  {
    img: brand_honey_Hosting,
    alt: "Brand Honey Hosting",
  },
  {
    img: brand_dynamic,
    alt: "Brand Dynamic",
  },
];
export default function Brands() {
  return (
    <article className="md:mx-[74px] my-10 md:my-16 ">
      <section className="flex flex-wrap justify-center items-center space-y-3">
        {brand_Img_Array.map((element, index) => (
          <div className="basis-1/2 sm:basis-1/5  h-[65px]">
            <img
              className="w-fit h-fit"
              src={element.img}
              alt={element.alt}
              loading="lazy"
              key={`BrandImage_${index}`}
            />
          </div>
        ))}
      </section>
    </article>
  );
}
