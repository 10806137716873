import React from "react";
import topMobileAward1Png from "../../../Images/Award/top-mobile-award 1.png";
import techimplyImg1Png from "../../../Images/Award/techimply-img 1.png";
import ClipPathGroupPng from "../../../Images/Award/Clip path group.png";
import Vector1Png from "../../../Images/Award/Vector (1).png";
import Vector2Png from "../../../Images/Award/Vector (2).png";
import topDevCompany1Png from "../../../Images/Award/top-dev-company 1.png";

const award_Details = [
  {
    img: topMobileAward1Png,
    imgAlt: "top Mobile Award1 Png",
  },
  {
    img: techimplyImg1Png,
    imgAlt: "techimply 1 Png",
  },
  {
    img: ClipPathGroupPng,
    imgAlt: "Clip Path Group Png",
  },
  {
    img: Vector1Png,
    imgAlt: "Vector 1 Png",
  },
  {
    img: Vector2Png,
    imgAlt: "Vector 2 Png",
  },
  {
    img: topDevCompany1Png,
    imgAlt: "top Dev Company 1 Png",
  },
];

export default function AwardsList() {
  return (
    <article className="flex flex-wrap justify-center items-center">
      {award_Details.map((element, index) => (
        <div className=" basis-1/2 sm:basis-1/4 lg:basis-1/6 h-[130px]">
          <img
            src={element.img}
            className="w-[100px] h-[100px] md:w-[130px] md:h-[130px] mx-auto"
            alt={element.imgAlt}
            key={element.imgAlt + index}
            loading="lazy"
          />
        </div>
      ))}
    </article>
  );
}
