import React from "react";
import Iphone12FlatWhite from "../../../../Images/Iphone 12 Flat - White.png";
import Union from "../../../../Images/Union.png";
export default function Image() {
  return (
    <article className="py-11">
      <div className="flex  justify-center items-center h-[450px] md:h-[559.17px] w-full md:w-[519px] mx-auto overflow-hidden">
        <div className="w-full h-[450px] md:w-[80%] md:h-[559.17px] my-auto mx-auto overflow-hidden">
          <img
            className="w-full h-full mt-3 bg-auto bg-no-repeat bg-center"
            src={Iphone12FlatWhite}
            loading="lazy"
            alt="Iphone 12 Flat White"
          />
        </div>
      </div>
      <div className="h-12 w-12 ms-3 md:ms-[102px]">
        <img className="w-full h-full" src={Union} alt="Union" />
      </div>
    </article>
  );
}
