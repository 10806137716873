import "./App.css";
import { BrowserRouter as Main, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";

function App() {
  return (
    <Main>
      <Routes>
        <Route exact path="/" Component={HomePage}></Route>
      </Routes>
    </Main>
  );
}

export default App;
