import React from "react";
import Header from "../../Helper/Header";
import ImageAndDetails from "./ImageAndDetails";
const Why_Choose_Us_Paragraph =
  "We take the complete responsibility of building apps which are built to rule the Play Store. Our association doesn't just end there. We ensure that the world knows about your offering through our extensive marketing efforts.";
export default function WhyChooseUs() {
  return (
    <section className="mb-12 md:mb-0 ">
      <Header title="Why Choose us" paragraph={Why_Choose_Us_Paragraph} />
      <ImageAndDetails />
    </section>
  );
}
