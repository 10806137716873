import React from "react";
import propTypes from "prop-types";

export default function TechnologyUsed({ title, imageCardCss, imagesArray }) {
  return (
    <article className=" space-y-3">
      <header className="header-text-Brand-Color font-[700] text-lg md:text-[24px] ">
        {title}
      </header>
      <section className="flex justify-between items-center w-10/12 sm:w-1/4 md:w-1/3 lg:w-1/3">
        {imagesArray.map((element, index) => (
          <div className={`${imageCardCss} border border-white`}>
            <img
              className="w-full h-full"
              src={element.image}
              alt={element.imageAlt}
              loading="lazy"
            />
          </div>
        ))}
      </section>
    </article>
  );
}

TechnologyUsed.prototype = {
  title: propTypes.string.isRequired,
  imagesArray: propTypes.object.isRequired,
};
