import React from "react";
import CardHeader from "../Components/CardHeader";
import TechnologyUsed from "../Components/TechnologyUsed";
import VectorPng from "../../../Images/Projects/Vector.png";
import VectoGroup127Png from "../../../Images/Projects/Group 127.png";
import VectoGroup128Png from "../../../Images/Projects/Group 128.png";

const cardTitle = "Ecommerce";
const card_2nd_Title = "Myndia";
const cardHeaderParagraph =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  ea commodo consequat. Duis aute irure dolor occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum";
const imagesArray = [
  {
    image: VectorPng,
    imageAlt: "Apple_Png",
  },
  {
    image: VectoGroup127Png,
    imageAlt: "Android_Png",
  },
  {
    image: VectoGroup128Png,
    imageAlt: "React_Png",
  },
];
export default function Details() {
  return (
    <article className="basis-full md:basis-2/5 w-full space-y-3 md:space-y-7">
      <CardHeader
        card_1st_Title={cardTitle}
        card_2nd_Title={card_2nd_Title}
        card_1st_Paragraph={cardHeaderParagraph}
        key={cardHeaderParagraph}
      />
      <TechnologyUsed title="Technology used" imagesArray={imagesArray} />
    </article>
  );
}
