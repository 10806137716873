import React from "react";
import BrandsWeHaveWorkedWith from "../Components/BrandsWeHaveWorkedWith";
import OurService from "../Components/OurService";
import Projects from "../Components/Projects";

export default function HomePage() {
  return (
    <>
      <BrandsWeHaveWorkedWith />
      <OurService />
      <Projects />
    </>
  );
}
