import React from "react";

export default function EllipseDots({ image, imageAlt, Alignment_css }) {
  return (
    <div className={`absolute ${Alignment_css}`}>
      <img
        className="w-full h-full"
        src={image}
        loading="lazy"
        alt={imageAlt}
      />
    </div>
  );
}
